// This file is responsible for setting up import shims on dev sites.
// It's a global script that is executed before the impport maps so no import() statements can be present here

function init() {
    const vConsole =  window['console'];
    // const baseCdnUrl = document.querySelector<HTMLMetaElement>('meta[name="o365-cdn-base-url"]')?.content;
    const staticScriptsBaseUrl = `${window.location.origin}/nt/scripts/`;
    let importMapCheckDone = false;

    (window as any).esmsInitOptions = {
        shimMode: true,
        resolve: async (pId: string, pParentUrl: string, pResolve: any) => {
            if (!importMapCheckDone) {
                validateInitialMaps();                
            }

            if (self?.__o365_shims__?.logResolve) {
                self.__o365_shims__.logResolve(pId, pParentUrl);
            }

            await populateCheckedOutLibraries();

            const checkedOutLib = resolveCheckedOutLibrary(pId);

            let result = undefined;
            if (checkedOutLib) {
                result = await customLibraryResolve(checkedOutLib.name, pParentUrl, checkedOutLib.fileImport);
            } else {
                result = pResolve(pId, pParentUrl);
            }
            
            return result;
        },
        mapOverrides: true,
    }
    

    const checkedOutLibraries = new Set<string>();

    // Temp enfoce o365-designer from appcache. Libs do not have support for css files on the cdn...
    checkedOutLibraries.add('o365-designer');
    if (window.location.host == 'localhost' || window.location.host == 'dev-test.omega365.com') {
        checkedOutLibraries.add('o365-login');
    }

    const importMapsCache = new Map<string, {
        main: string,
        exports?: Record<string, string>
    }>();

    function getImportMapsNode() {
        return document.querySelector('script[type="importmap-shim"]');
    }

    function resolveCheckedOutLibrary(pSpecifier: string) {
        if (pSpecifier.startsWith('.') || pSpecifier.startsWith('/')) {
            return undefined;
        }

        const path = pSpecifier.split('/');

        if (path.length > 0 && path.length <= 2) {
            if (checkedOutLibraries.has(path[0])) {
                return {
                    name: path[0],
                    fileImport: path[1]
                }
            }
        }
        return undefined
    }

    /**
     * Make sure that importShim maps are populated with entries from
     * script[type="importmap-shim"] 
     */
    function validateInitialMaps() {
        importMapCheckDone = true;
        const maps = (window as any).importShim.getImportMap();
        if (maps == null || maps.imports == null || !Object.keys(maps.imports).length) {
            const importMaps = JSON.parse(getImportMapsNode()!.innerHTML);
            (window as any).importShim.addImportMap(importMaps);
        }
    }

    async function customLibraryResolve(pId: string, _pParentUrl: string, pFileImport?: string) {
        const info = await getAppCacheModuleInfoPromise(pId);
        if (pFileImport) {
            return info?.exports?.[pId + '/' + pFileImport];
        } else {
            return info?.main;
        }
    }

    const appCacheModuleInfoPromises = new Map<string, Promise<{ main: string, exports?: Record<string, string> } | undefined>>();

    function getAppCacheModuleInfoPromise(pAppId: string) {
        if (appCacheModuleInfoPromises.has(pAppId)) {
            return appCacheModuleInfoPromises.get(pAppId)!;
        }
        const promise = getAppCacheModuleInfo(pAppId);
        appCacheModuleInfoPromises.set(pAppId, promise);
        return promise;
    }

    async function getAppCacheModuleInfo(pAppId: string) {
        if (!importMapsCache.has(pAppId)) {
            const result = await fetch(`/api/staticfiles/import-map/appfiles/${pAppId}.json?fingerprint=${getDependenciesFingerPrint(pAppId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .catch(() => ({ imports: {} }));
            const info = transformMaps(result, pAppId);

            const loadedMaps = (window as any).importShim.getImportMap();
            const newMaps = {
                imports: {...loadedMaps.imports, ...result.imports},
                scopes: { ...loadedMaps.scopes, ...result.scopes}
            };

            (window as any).importShim.addImportMap(newMaps);

            const exports: Record<string, string> = {};
            if (info.exports) {
                for (const key of info.exports) {
                    exports[`${pAppId}/${key}`] = result.imports[`${pAppId}/${key}`];
                }
            }

            importMapsCache.set(pAppId, {
                main: result.imports[pAppId],
                exports: exports,
            });
        }

        return importMapsCache.get(pAppId);
    }

    function transformMaps(pMap: {
        imports: Record<string, string>,
        scopes?: Record<string, Record<string, string>>
        configJson?: string
    }, pModuleId: string) {
        if (pMap.scopes == null) {
            pMap.scopes = {};
        };

        let appConfig: { main?: string, exports?: string[] } | null = null;
        if (pMap.configJson) {
            try {
                appConfig = JSON.parse(pMap.configJson);
            } catch (ex) {
                console.error(ex);
            }
        }

        const scopePrefix = `/nt/scripts/apps/${pModuleId}/`;
        let indexUrl = '';
        let removedEntries: [string, string][] = [];

        const keys = Object.keys(pMap.imports);
        for (const key of keys) {
            if (key.startsWith(scopePrefix)) {
                const value = pMap.imports[key];
                delete pMap.imports[key];
                pMap.imports[key.replace(scopePrefix, '')] = value;
            }
        }
        let mainEntryKey = appConfig?.main
        for (const key in pMap.imports) {
            if (mainEntryKey ? (key == mainEntryKey) : (key.endsWith('index.ts') || key.endsWith('index.js'))) {
                mainEntryKey = key;
                indexUrl = pMap.imports[key];
                delete pMap.imports[key];
            } else if (key.startsWith(scopePrefix)) {
                const url = pMap.imports[key];
                delete pMap.imports[key];
                removedEntries.push([key.replace(scopePrefix, ''), url]);
            }
        }

        for (const entry of removedEntries) {
            pMap.imports[entry[0]] = entry[1];
        }


        const copy = {...pMap.imports};
        pMap.scopes[scopePrefix] = {};
        pMap.imports = {
            [pModuleId]: indexUrl,
        };
        
        if (appConfig && appConfig.exports) {
            for (const explicitExport of appConfig.exports) {
                pMap.imports[`${pModuleId}/${explicitExport}`] = copy[explicitExport];
            }
        }
 
        for (const entry of Object.entries(copy)) {
            pMap.scopes[scopePrefix][scopePrefix + entry[0]] = entry[1];
        }


        return {
            main: mainEntryKey,
            exports: appConfig?.exports
        }
    }

    function getModuleScopeFromUrl(pUrl: string) {
        if (pUrl.startsWith(staticScriptsBaseUrl)) {
            if (pUrl.startsWith(staticScriptsBaseUrl + 'site')) {
                return 'site';
            } else {
                return pUrl.split('/').at(-2);
            }
        } else {
            return undefined;
        }
    }

    let checkedOutLibrariesPopulatedPromise: Promise<void> | null = null;
    async function populateCheckedOutLibraries() {
        if (checkedOutLibrariesPopulatedPromise) { return checkedOutLibrariesPopulatedPromise; }
        checkedOutLibrariesPopulatedPromise = new Promise<void>(async (res) => {
            try {

                if (window.self !== window.top) {
                    // This is an iframe, check if the top window has any overrides
                    const libs = window.top?.__o365_shims__?.checkedOutLibraries;
                    if (libs) {
                        for (const lib of libs) {
                            checkedOutLibraries.add(lib);
                        }
                        res();
                        return;
                    }
                }

                const params = new URLSearchParams(window.location.search);
                if (params.has('o_libs')) {
                    // Query parameters contain checkout overrides. Add only these ones.
                    const libs = params.get('o_libs')?.split(',') ?? [];
                    for (const lib of libs) {
                        checkedOutLibraries.add(lib);
                    }
                    if (window.__o365_shims__ == null) {
                        window.__o365_shims__ = {};    
                    }
                    window.__o365_shims__.checkedOutLibraries == Array.from(checkedOutLibraries);
                    
                } else {
                    // Load library checkouts from local storage 
                    const checkedOutLibs = localStorage.getItem('o365-dev-modules-shims_checked-out-app-libs');
                    if (checkedOutLibs) {
                        const libs = JSON.parse(checkedOutLibs);
                        for (const lib of libs) {
                            checkedOutLibraries.add(lib);
                        }
                    }
                }
            } catch (ex) {
                console.warn(ex);
            }

            // TODO: Change this to IndexDB so that it can be used as an in-browser file system
            // const libs = await db_retrieveCheckedOutLibs();
            res();
        });
        return checkedOutLibrariesPopulatedPromise;
    }

    /**
     * Helper function for getting the fingerprint of a library.
     */
    function getDependenciesFingerPrint(pId: string) {
        let dependenciesFingerPrint = document.querySelector<HTMLMetaElement>(`meta[name="o365-app-dependency-fingerprint-${pId}"]`)?.content;
        if (!dependenciesFingerPrint) {
            vConsole.warn(`No tag helper found for ${pId} dependencies fingerprints, will use random number instead`);
            dependenciesFingerPrint = `${Math.round((Math.random() * 10_000))}`;
        }
        return dependenciesFingerPrint;
    }

    // --- IndexDB ---

    // This part is not used for now. Will be utilized later on for loading local changes per user.

    let dbPromise: Promise<IDBDatabase | undefined> | undefined; 
    function getDb() {
        if (dbPromise) {
            return dbPromise;
        }
        const DBOpenRequest = indexedDB.open('o365-dev-modules-shims', 1);
        let db: IDBDatabase | undefined = undefined;

        let resovleDbOpen = (_pDb?: IDBDatabase) => {};
        dbPromise = new Promise<IDBDatabase | undefined>((res) => {
            resovleDbOpen = res;
        });

        DBOpenRequest.onerror = () => {
            vConsole.error('Failed to open database for library overrides on import maps')
            resovleDbOpen();
        };

        DBOpenRequest.onsuccess = () => {
            db = DBOpenRequest.result;
            resovleDbOpen(db);
        };

        DBOpenRequest.onupgradeneeded = () => {
            db = DBOpenRequest.result;

            // db.checked-out-app-libs
            let objectStore = db.createObjectStore('checked-out-app-libs', { keyPath: 'ID', autoIncrement: false },)
        };

        return dbPromise;
    }

    async function db_retrieveCheckedOutLibs() {
        const db = await getDb();
        if (db == null) { return; }

        const transaction = db.transaction(['checked-out-app-libs'], 'readonly');
        const objectStore = transaction.objectStore('checked-out-app-libs');

        const result = await wrapTransactionRequestToPromise(objectStore.getAll());

        return result;
    }

    function wrapTransactionRequestToPromise<T = any>(pRequest: IDBRequest<T>) {
        let resolve = (_r: T) => {};
        let reject = (_ex: DOMException | null) =>  {};
        const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        pRequest.onsuccess = () => {
            resolve(pRequest.result);
        };

        pRequest.onerror = () => {
            reject(pRequest.error);
        };

        return promise;
    }

}

interface Window {
    __o365_shims__?: {
        checkedOutLibraries?: string[];
        logResolve?: (pId: string, pParentUrl?: string) => void;
    }
};


init();
